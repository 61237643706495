import React from 'react'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'

import usePostForm from '../api/mutations/usePostForm'

import Layout from '../components/layout'
import Seo from '../components/seo'
import FormField, { InputType } from '../components/FormField'

interface ValutaImmobileProps {
  pageContext: {
    servizi: { value: string; label: string }[]
  }
}

export type FormType = {
  nomeCognome: string
  email: string
  telefono: string
  indirizzo: string
  messaggio: string
  immobile?: string
  privacy: boolean
  tipologiaRichiesta: 'VALUTA_IMMOBILE' | 'INTERESSATO_A_IMMOBILE'
}

const valutaImmobile: React.FC<ValutaImmobileProps> = ({ pageContext: { servizi } }) => {
  const mutation = usePostForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormType>({
    defaultValues: {
      nomeCognome: '',
      email: '',
      telefono: '',
      indirizzo: '',
      messaggio: '',
      privacy: false,
      tipologiaRichiesta: 'VALUTA_IMMOBILE',
    },
  })
  const onSubmit = (data) => {
    reset()
    mutation.mutate(data)
  }
  return (
    <>
      <Seo title="Valuta il tuo immobile" description="..." />
      <Layout servizi={servizi}>
        <div className="max-w-4xl mx-auto px-6 py-12">
          <h1 className="text-4xl font-semibold text-center pb-20 text-gray-900 dark:text-gray-100">
            Sei interessato a valutare il tuo immobile?
          </h1>
          <div className="flex flex-col md:flex-row items-center md:items-start justify-between space-y-10 md:space-y-0 md:space-x-20">
            <div className="w-96">
              <img src="/valuta_immobile.png" alt="Valuta il tuo immobile" className="block mt-2 mb-10 rounded" />
              <div className="text-center md:text-left text-gray-700 dark:text-gray-300 md:px-6">
                <h3 className="text-2xl font-semibold mb-4">Contattaci senza impegno</h3>
                <p className="text-lg text-gray-600 dark:text-gray-400">
                  Dopo una visita all'immobile ti forniremo la valutazione di mercato nella totale riservatezza.
                </p>
              </div>
            </div>
            {mutation.isSuccess ? (
              <div className="py-4 px-6 rounded bg-lime-50 dark:bg-emerald-800 border border-lime-200 dark:border-emerald-800 text-base text-lime-600 dark:text-gray-200 font-semibold">
                <p className="mb-5">La tua richiesta di informazioni è stata inviata!</p>
                <p>Sarai ricontattato a breve da un nostro consulente.</p>
              </div>
            ) : (
              <form className="flex-1" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <FormField
                    fieldName="nomeCognome"
                    label="Nome e cognome"
                    type={InputType.Text}
                    validation={{
                      required: 'Inserisci nome e cognome',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    fieldName="email"
                    label="Email"
                    type={InputType.Text}
                    validation={{
                      required: 'Inserisci email',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    fieldName="telefono"
                    label="Telefono"
                    type={InputType.Text}
                    validation={{
                      required: 'Inserisci telefono',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    fieldName="indirizzo"
                    label="Indirizzo"
                    type={InputType.Text}
                    validation={{
                      required: 'Inserisci indirizzo',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    fieldName="messaggio"
                    label="Messaggio"
                    type={InputType.Textarea}
                    validation={{
                      required: 'Inserisci messaggio',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    fieldName="privacy"
                    label={() => (
                      <span>
                        Acconsento al trattamento dei miei dati come da{' '}
                        <a href="/informativa-privacy" target="_blank" rel="noopener noreferrer" className="underline">
                          informativa privacy
                        </a>
                      </span>
                    )}
                    type={InputType.Boolean}
                    validation={{
                      required: 'Acconsenti al trattamento dati',
                    }}
                    register={register}
                    errors={errors}
                  />
                </div>
                {mutation.isError && (
                  <div className="py-2 px-3 rounded bg-red-100 border border-red-300 text-xs font-semibold my-4 text-red-700">
                    È avvenuto un errore durante l'invio della richiesta. Ti chiediamo di riprovare o contattarci al n°
                    035&nbsp;564&nbsp;533.
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full text-center text-sm uppercase tracking-wider bg-lime-600 hover:bg-lime-700 dark:hover:bg-lime-500 hover:shadow-2xl py-2 px-3 text-white transition-colors rounded"
                  // className="mt-8 w-full rounded-md border shadow-sm border-gray-200 text-gray-700 focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50 hover:bg-gray-50 hover:text-gray-900 px-4 py-2"
                >
                  Invia richiesta
                </button>
              </form>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default valutaImmobile
